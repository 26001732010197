import appAuthConstants from 'constants/appAuthConstants';
import appSettings from "config/compiledConstants";

const apiBaseUrl = appAuthConstants.apiBaseUrl;
const eApiBaseUrl = appAuthConstants.eApiBaseUrl;

var minimalDateFormat = 'DD/MM/YYYY';

var workflowResourceType = {
    NAME: 'PersonalName',
    DEMOGRAPHIC: 'PersonalAdditionalInformation',
    MEDICAL_CONDITIONS: 'PersonalMedicalConditions',
    PERSONAL_CONTACTS: 'PersonalContact',
    WORK_CONTACTS: 'WorkContact',
    ADDRESS: 'PersonalAddresses',
    EMERGENCY_CONTACTS: 'PersonalEmergencyContact',
    SALARY: 'PersonalSalary',
    TAX_DETAILS: 'PersonalTax',
    BANK_ACCOUNTS: 'PersonalBank',
    SUPERANNUATION: 'PersonalSuper',
    ANNUAL_LEAVE: 'AnnualLeave',
    SICK_LEAVE: 'SickLeave',
    LONG_SERVICE_LEAVE: 'LongServiceLeave',
    USER_DEFINED_LEAVE: 'UserDefinedLeave',
    OTHER_LEAVE: 'OtherLeave',
};

var workflowType = {
    LEAVE: 'Leave',
    PERSONAL_CHANGES: 'PersonalChanges',
    TIMESHEETS: 'Timesheet',
};

var newStarterMenuOptions = {
    WELCOME: "Welcome",
    INTRODUCTION: "Introduction",
    PERSONAL_DETAILS: 'Personal Information',
    CONTACT: 'Contact Details',
    EMPLOYMENT: 'Employment',
    TAX_DETAILS: "Tax Details",
    SUPERANNUATION: 'Superannuation',
    BANK: 'Bank Account',
    SUMMARY: "Summary",
};

var newStarterOptionsSlugs = {
    WELCOME: "welcome",
    PERSONAL_DETAILS: "personal-details",
    CONTACT: "contact-details",
    EMPLOYMENT: "employment",
    TAX_DETAILS: "tax-details",
    SUPERANNUATION: "superannuation",
    BANK: "bank-account",
    SUMMARY: "summary",
};

var appConstants = {
    isAnalyticsEnabled: appSettings.IS_ANALYTICS_ENABLED,
    isAnalyticsDebugEnabled: appSettings.IS_ANALYTICS_DEBUG_ENABLED,
    gaTrackingId: appSettings.GOOGLE_ANALYTICS_TRACKING_ID,
    passwordStrengthScore: appSettings.PASSWORD_STRENGTH_SCORE,
    auth: appAuthConstants.authConfig,
    notificationOffset: appSettings.NOTIFICATION_OFFSET,
    documentTitle: 'ESS',
    whatsNewContent: appSettings.WHATS_NEW_PATH,
    intercomKey: appSettings.INTERCOM_KEY,
    actions: {

        PAGE_LOADED: "PAGE_LOADED",

        LOGIN: "LOGIN",

        ADD_ROLE: "ADD_ROLE",
        UPDATE_ROLE: "UPDATE_ROLE",
        REMOVE_ROLE: "REMOVE_ROLE",
        LOAD_SINGLE_ROLE: "LOAD_SINGLE_ROLE",
        LOAD_PAGED_ROLES: "LOAD_PAGED_ROLES",
        GET_ALL_REDIRECTED_APPROVERS: "GET_ALL_REDIRECTED_APPROVERS",

        VALIDATE_GUID: "VALIDATE_GUID",
        ACTIVATE_USER: "ACTIVATE_USER",
        ACTIVATE_ALL_USERS: "ACTIVATE_ALL_USERS",
        ACTIVATE_USERS: "ACTIVATE_USERS",        
        SEND_PASSCODE_GUID: "SEND_PASSCODE_GUID",

        GET_API_KEY: "GET_API_KEY",
        UPDATE_API_KEY: "UPDATE_API_KEY",
        ADD_CONFIGURATION: "ADD_CONFIGURATION",
        LOAD_WEBAPICONFIGURATIONS_FROM_SERVER: "LOAD_WEBAPICONFIGURATIONS_FROM_SERVER",
        ADD_WEBAPICONFIGURATION: "ADD_WEBAPICONFIGURATION",
        UPDATE_WEBAPICONFIGURATION: "UPDATE_WEBAPICONFIGURATION",
        REMOVE_WEBAPICONFIGURATION: "REMOVE_WEBAPICONFIGURATION",
        ADD_ADMIN_ROLE_WITH_DUPLICATE_PERMISSIONS: "ADD_ADMIN_ROLE_WITH_DUPLICATE_PERMISSIONS",
        GET_MCS_VERSIONS: "GET_MCS_VERSIONS",
        GET_DEFAULT_MCS_VERSION: "GET_DEFAULT_MCS_VERSION",

        GET_CONFIGURATION_VALUES: "GET_CONFIGURATION_VALUES",
        UPDATE_COMPANY_NAME: "UPDATE_COMPANY_NAME",
        UPDATE_MFA_ENABLE_NEW_MEMBER: "UPDATE_MFA_ENABLE_NEW_MEMBER",

        ACTIVATIONS: {
            GET_ACTIVATION_TOKEN: "GET_ACTIVATION_TOKEN",
        },

        APPROVALS: {
            GET_APPROVALS: "GET_APPROVALS",
            GET_OTHER_APPROVALS: "GET_OTHER_APPROVALS",
            APPROVE: "APPROVE",
            DECLINE: "DECLINE",
            GET_APPROVAL_COUNT: "GET_APPROVAL_COUNT",
            SET_APPROVAL_ACTION: "SET_APPROVAL_ACTION",
        },

        AUTH: {
            GET_USER_IDP: "GET_USER_IDP",
        },

        CONFIGURATION_OPTIONS: {
            GET_CONFIG_OPTIONS: "GET_CONFIG_OPTIONS",
        },

        LEAVE: {
            GET_LEAVE_TYPES: "GET_LEAVE_TYPES",
            UPDATE_LEAVE_TYPE: "UPDATE_LEAVE_TYPE",
            DOWNLOAD_LEAVE_ATTACHMENT: "DOWNLOAD_LEAVE_ATTACHMENT",
            GET_LEAVE_REASONS: "GET_LEAVE_REASONS",
        },

        LOADER: {
            TOGGLE: "TOGGLE",
            SHOW: "SHOW",
            HIDE: "HIDE",
        },

        LOOKUPS: {
            LOAD_LOOKUP: "LOAD_LOOKUP",
            GET_SUBURBS: "GET_SUBURBS",
            GET_STATES: "GET_STATES",
            GET_COUNTRIES: "GET_COUNTRIES",
            GENDER: "Gender",
            MARITAL_STATUS: "MaritalStatus",
            INDIGENOUS_STATUS: "IndigenousStatus",
            GET_JOB_CLASSIFICATIONS: "GET_JOB_CLASSIFICATIONS",
            BASIS_OF_EMPLOYMENT: "BasisOfEmployment",
            HOURS_OF_WORK_FREQUENCY: "HoursOfWorkFrequency",
            NORMAL_RATE_FREQUENCY: "NormalRateFrequency",
        },

        MEMBERSHIPS: {
            CHECK_MEMBERSHIP: "CHECK_MEMBERSHIP",
            ADD_MEMBERSHIP: "ADD_MEMBERSHIP",
            DELETE_MEMBERSHIP: "DELETE_MEMBERSHIP",
            DELETE_ADMIN: "DELETE_ADMIN",
            SET_MEMBER_ROLE: "SET_MEMBER_ROLE",
            SET_MANAGER_ROLE: "SET_MANAGER_ROLE",
            GET_ROLES_AND_TEAMID: "GET_ROLES_AND_TEAMID",
            ADD_ADMIN: "ADD_ADMIN",
        },

        MODAL: {
            OPEN: "OPEN",
            CLOSE: "CLOSE",
            DISABLE_ESCAPE: "DISABLE_ESCAPE",
            ENABLE_ESCAPE: "ENABLE_ESCAPE",
            REFRESH: "REFRESH",
            LOADING: "LOADING",
        },

        NEW_STARTERS: {
            ADD_NEW_STARTER: "ADD_NEW_STARTER",
            GET_MORE_NEW_STARTERS: "GET_MORE_NEW_STARTERS",
            GET_NEW_STARTERS: "GET_NEW_STARTERS",            
            DELETE_NEW_STARTER: "DELETE_NEW_STARTER",
            GET_NEW_STARTER: "GET_NEW_STARTER",
            UPDATE_NEW_STARTER: "UPDATE_NEW_STARTER",
            RESET_NEW_STARTER: "RESET_NEW_STARTER",
            UPDATE_NEW_STARTER_STATUS: "UPDATE_NEW_STARTER_STATUS",
            GET_NEW_STARTER_STATUS: "GET_NEW_STARTER_STATUS",
            GET_NEW_STARTERS_COUNT: "GET_NEW_STARTERS_COUNT",
            GET_NEW_STARTER_COMPLETED_PAGES: "GET_NEW_STARTER_COMPLETED_PAGES",
            FINALISE_NEW_STARTER: "FINALISE_NEW_STARTER",
            GET_ACTION_REQUIRED_COUNT: "GET_ACTION_REQUIRED_COUNT",      
            RESEND_FORMS_NEW_STARTER: "RESEND_FORMS_NEW_STARTER",      
        },

        PEOPLE: {
            LOAD_PEOPLE_FROM_SERVER: "LOAD_EMPLOYEES_FROM_SERVER",
            LOAD_MORE_PEOPLE_FROM_SERVER: "LOAD_MORE_PEOPLE_FROM_SERVER",
            LOAD_MORE_FILTERED_PEOPLE_FROM_SERVER: "LOAD_MORE_FILTERED_PEOPLE_FROM_SERVER",
            LOAD_FILTERED_PEOPLE: "LOAD_FILTERED_PEOPLE",
            LOAD_SINGLE_PERSON: "LOAD_SINGLE_PERSON",
            APPEND_PEOPLE_FROM_SERVER: "APPEND_PEOPLE_FROM_SERVER",
            GET_SINGLE_PERSON: "GET_SINGLE_EMPLOYEE",
            GET_SINGLE_PERSON_NAME: "GET_SINGLE_PERSON_NAME",
            UPDATE_SINGLE_PERSON_NAME: "UPDATE_SINGLE_PERSON_NAME",
            GET_SINGLE_PERSON_DEMOGRAPHIC: "GET_SINGLE_PERSON_DEMOGRAPHIC",
            UPDATE_SINGLE_PERSON_DEMOGRAPHIC: "UPDATE_SINGLE_PERSON_DEMOGRAPHIC",
            GET_SINGLE_PERSON_MEDICAL_CONDITIONS: "GET_SINGLE_PERSON_MEDICAL_CONDITIONS",
            UPDATE_SINGLE_PERSON_MEDICAL_CONDITIONS: "UPDATE_SINGLE_PERSON_MEDICAL_CONDITIONS",
            GET_SINGLE_PERSON_WORK_CONTACTS: "GET_SINGLE_PERSON_WORK_CONTACTS",
            UPDATE_SINGLE_PERSON_WORK_CONTACTS: "UPDATE_SINGLE_PERSON_WORK_CONTACTS",
            GET_SINGLE_PERSON_PERSONAL_CONTACTS: "GET_SINGLE_PERSON_PERSONAL_CONTACTS",
            UPDATE_SINGLE_PERSON_PERSONAL_CONTACTS: "UPDATE_SINGLE_PERSON_PERSONAL_CONTACTS",
            GET_SINGLE_PERSON_EMAIL_CONTACTS: "GET_SINGLE_PERSON_EMAIL_CONTACTS",
            UPDATE_PERSON_DEFAULT_EMAIL_CONTACTS: "UPDATE_PERSON_DEFAULT_EMAIL_CONTACTS",
            GET_SINGLE_PERSON_ADDRESS_CONTACTS: "GET_SINGLE_PERSON_ADDRESS_CONTACTS",
            GET_SINGLE_PERSON_BANKS: "GET_SINGLE_PERSON_BANKS",
            UPDATE_SINGLE_PERSON_BANKS: "UPDATE_SINGLE_PERSON_BANKS",
            GET_SINGLE_PERSON_EMERGENCY_CONTACTS: "GET_SINGLE_PERSON_EMERGENCY_CONTACTS",
            UPDATE_SINGLE_PERSON_EMERGENCY_CONTACTS: "UPDATE_SINGLE_PERSON_EMERGENCY_CONTACTS",
            REMOVE_PERSON_EMERGENCY_CONTACTS: "REMOVE_PERSON_EMERGENCY_CONTACTS",
            GET_SINGLE_PERSON_LEAVE: "GET_SINGLE_PERSON_LEAVE",
            GET_SINGLE_PERSON_LEAVE_ALL: "GET_SINGLE_PERSON_LEAVE_ALL",
            GET_SINGLE_PERSON_LEAVE_UPCOMING: "GET_SINGLE_PERSON_LEAVE_UPCOMING",
            GET_SINGLE_PERSON_LEAVE_PENDING: "GET_SINGLE_PERSON_LEAVE_PENDING",
            UPDATE_SINGLE_PERSON_LEAVE: "UPDATE_SINGLE_PERSON_LEAVE",
            UPDATE_LEAVE_APPS_TRACES: 'UPDATE_LEAVE_APPS_TRACES',
            GET_SINGLE_PERSON_LEAVE_REASONS: "GET_SINGLE_PERSON_LEAVE_REASONS",
            UPDATE_SINGLE_PERSON_LEAVE_REASONS: "UPDATE_SINGLE_PERSON_LEAVE_REASONS",
            GET_SINGLE_PERSON_LEAVE_BALANCES: "GET_SINGLE_PERSON_LEAVE_BALANCES",
            GET_LOGGED_USER_LEAVE_BALANCES: "GET_LOGGED_USER_LEAVE_BALANCES",
            GET_SINGLE_PERSON_PAY_ADVICES: "GET_SINGLE_PERSON_PAY_ADVICES",
            GET_SINGLE_PERSON_PAY_SUMMARIES: "GET_SINGLE_PERSON_PAY_SUMMARIES",
            DOWNLOAD_PERSON_PAY_ADVICE_PDF: "DOWNLOAD_PERSON_PAY_ADVICE_PDF",
            DOWNLOAD_PERSON_PAY_SUMMARY_PDF: "DOWNLOAD_PERSON_PAY_SUMMARY_PDF",
            ADD_PERSON: "ADD_EMPLOYEE",
            REMOVE_PERSON: "REMOVE_EMPLOYEE",
            UPDATE_PERSON: "UPDATE_EMPLOYEE",
            GET_SINGLE_PERSON_WORKFLOW_DETAILS: "GET_SINGLE_PERSON_WORKFLOW_DETAILS",
            GET_SINGLE_PERSON_EMPLOYMENT: "GET_SINGLE_PERSON_EMPLOYMENT",
            GET_SINGLE_PERSON_EMPLOYER: "GET_SINGLE_PERSON_EMPLOYER",
            GET_SINGLE_PERSON_SALARY: "GET_SINGLE_PERSON_SALARY",
            GET_SINGLE_PERSON_TAX: "GET_SINGLE_PERSON_TAX",
            GET_SINGLE_PERSON_TFN: "GET_SINGLE_PERSON_TFN",
            GET_SINGLE_PERSON_ROLES_TEAMS: "GET_SINGLE_PERSON_ROLES_TEAMS",
            GET_SINGLE_PERSON_ASSOCIATED_TEAMS: "GET_SINGLE_PERSON_ASSOCIATED_TEAMS",
            GET_AVATAR: "GET_AVATAR",
            UPDATE_AVATAR: "UPDATE_AVATAR",
            DELETE_AVATAR: "DELETE_AVATAR",
            GET_SINGLE_PERSON_WORK_DAYS_HOURS: "GET_SINGLE_PERSON_WORK_DAYS_HOURS",
            GET_SINGLE_PERSON_LEAVE_ENTITLEMENTS: "GET_SINGLE_PERSON_LEAVE_ENTITLEMENTS",
            GET_DELEGATE_APPROVER: "GET_DELEGATE_APPROVER",
            EDIT_DELEGATE_APPROVER: "EDIT_DELEGATE_APPROVER",
            DELETE_DELEGATE_APPROVER: "DELETE_DELEGATE_APPROVER",
            GET_DELEGATED_TO_ME: "GET_DELEGATED_TO_ME",
            GET_SINGLE_PERSON_APPROVER_TYPES: "GET_SINGLE_PERSON_APPROVER_TYPES",
            GET_SINGLE_PERSON_LEAVE_BALANCE_VALIDATION: "GET_SINGLE_PERSON_LEAVE_BALANCE_VALIDATION",
            GET_SINGLE_PERSON_SUPERANNUATION: "GET_SINGLE_PERSON_SUPERANNUATION",
            GET_SINGLE_PERSON_TIMESHEETS: "GET_SINGLE_PERSON_TIMESHEETS",
            UPDATE_SINGLE_PERSON_TIMESHEETS: "UPDATE_SINGLE_PERSON_TIMESHEETS",
        },

        PERMISSIONS: {
            GET_PERMISSIONS: "GET_PERMISSIONS",
            GET_PERMISSIONS_BY_ROLE: "GET_PERMISSIONS_BY_ROLE",
            UPDATE_ROLE_PERMISSIONS: "UPDATE_ROLE_PERMISSIONS",
        },

        PERSON: {
            ACTIVATE_IDENTITY: "ACTIVATE_IDENTITY",            
            CANCEL_ACTIVATION: "CANCEL_ACTIVATION",
            CLOSE_CURRENT_PERSON_IDENTITY: "CLOSE_CURRENT_PERSON_IDENTITY",
            CLOSE_PERSON_IDENTITY: "CLOSE_PERSON_IDENTITY",
            GET_ACTIVATION_INFO: "GET_ACTIVATION_INFO",
            GET_COMPANY_LOGO: "GET_COMPANY_LOGO",
            GET_LOGGED_IN_PERSON: "GET_LOGGED_IN_PERSON",
            GET_LOGGED_IN_PERSON_PROFILE: "GET_LOGGED_IN_PERSON_PROFILE",
            GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
            GET_PERSON_IDENTITY: "GET_PERSON_IDENTITY",
            LOGIN_PERSON: "LOGIN_PERSON",
            UPDATE_LAST_LOGIN: "UPDATE_LAST_LOGIN",
            REOPEN_PERSON_IDENTITY: "REOPEN_PERSON_IDENTITY",
            SEND_FORGOT_PASSWORD: "SEND_FORGOT_PASSWORD",
            SEND_RESET_IDENTITY_PASSWORD: "SEND_RESET_IDENTITY_PASSWORD",
            SET_NOTIFICATION_DATE: "SET_NOTIFICATION_DATE",
            UPDATE_USERNAME: "UPDATE_USERNAME",
            UPLOAD_COMPANY_LOGO: "UPLOAD_COMPANY_LOGO",
            RESET_MFA: "RESET_MFA",
        },

        REPORTS: {
            LEAVE_BALANCE_REPORT: "LEAVE_BALANCE_REPORT",
            MEDICAL_AND_EMERGENCY_REPORT: "MEDICAL-AND-EMERGENCY-REPORT",
            LEAVE_HISTORY_REPORT: "LEAVE_HISTORY_REPORT",
            DOWNLOAD_REPORT: "DOWNLOAD_REPORT",
            APPLIED_LEAVE_BY_DATE: "APPLIED_LEAVE_BY_DATE",
        },

        TEAMS: {
            GET_PEOPLE_ACTIVATION_STATUS: "GET_PEOPLE_ACTIVATION_STATUS",
            GET_ALL_TEAMS: "GET_ALL_TEAMS",
            GET_ALL_TEAMS_BY_DISPLAY_TYPE: "GET_ALL_TEAMS_BY_DISPLAY_TYPE",
            GET_MORE_TEAMS: "GET_MORE_TEAMS",
            GET_SINGLE_TEAM: "GET_SINGLE_TEAM",
            GET_SINGLE_TEAM_MEMBERS: "GET_SINGLE_TEAM_MEMBERS",
            GET_MORE_SINGLE_TEAM_MEMBERS: "GET_MORE_SINGLE_TEAM_MEMBERS",
            GET_SINGLE_TEAM_MANAGERS: "GET_SINGLE_TEAM_MANAGERS",
            GET_MORE_SINGLE_TEAM_MANAGERS: "GET_MORE_SINGLE_TEAM_MANAGERS",
            GET_MULTIPLE_TEAMS: "GET_MULTIPLE_TEAMS",
            GET_MULTIPLE_TEAM_MEMBERS: "GET_MULTIPLE_TEAM_MEMBERS",
            GET_MULTIPLE_TEAM_MANAGERS: "GET_MULTIPLE_TEAM_MANAGERS", 
            GET_USERS_MANAGERS: "GET_USERS_MANAGERS",
            GET_USERS_MEMBERS_ON_LEAVE: "GET_USERS_MEMBERS_ON_LEAVE",
            ADD_TEAM: "ADD_TEAM",
            EDIT_TEAM: "EDIT_TEAM",
            DELETE_TEAM: "DELETE_TEAM",
            LOAD_FILTERED_TEAMS: "LOAD_FILTERED_TEAMS",
            GET_TEAM_MEMBERS_COUNT: "GET_TEAM_MEMBERS_COUNT",
            REMOVE_PERSON_EMERGENCY_CONTACTS: "REMOVE_PERSON_EMERGENCY_CONTACTS",
            GET_UNASSIGNED_TEAM_INFO: "GET_UNASSIGNED_TEAM_INFO",
        },

        WEBAPI: {
            GET_WEB_API_LOGS: "GET_WEB_API_LOGS",
            GET_MORE_WEB_API_LOGS: "GET_MORE_WEB_API_LOGS",
            SYNC_ALL_WEBAPI_DATA: "SYNC_ALL_WEBAPI_DATA",
            SYNC_LEAVE_WEBAPI_DATA: "SYNC_LEAVE_WEBAPI_DATA",
            SYNC_WEBAPI_DATA: "SYNC_WEBAPI_DATA",
            GET_DEFAULT_SUPER_FUNDS: "GET_DEFAULT_SUPER_FUNDS",
            UPDATE_DEFAULT_SUPER_FUND: "UPDATE_DEFAULT_SUPER_FUND",
            SYNC_WEBAPI_TEAMS: "SYNC_WEBAPI_TEAMS",
        },

        WORKFLOWS: {
            GET_WORKFLOW_SCOPES: "GET_WORKFLOW_SCOPES",
            GET_USER_WORKFLOW_SCOPES: "GET_USER_WORKFLOW_SCOPES",
            GET_SINGLE_WORKFLOW_SCOPE: "GET_SINGLE_WORKFLOW_SCOPE",
            ADD_WORKFLOW: "ADD_WORKFLOW",
            UPDATE_WORKFLOW: "UPDATE_WORKFLOW",
        },

        WORKSPACE_MIGRATION: {
            GET_MIGRATION_URL: "GET_MIGRATION_URL",
        },
    },
    avatarColours: [
        '#007f64',
        '#e8397b',
        '#8dac26',
        '#f73c29',
        '#950961',
        '#00a4cf',
        '#235074',
        '#76165e',
        '#f22c44',
        '#c22061',
        '#ba1662',
        '#188f57',
        '#ccae11',
        '#ff6800',
        '#00adc5',
        '#2a3d60',
        '#0f7fa7',
        '#c0ae15',
        '#1b6388',
        '#00a9ca',
        '#80ab2b',
        '#00b29f',
        '#8a0e60',
        '#74ab2f',
        '#ffb000',
        '#ad0663',
        '#00b7ba',
        '#0891bb',
        '#b10b62',
        '#ca2a60',
        '#26476a',
        '#219452',
        '#f3296f',
        '#ff7200',
        '#29994e',
        '#f03d82',
        '#ff8500',
        '#13759d',
        '#ff5e00',
        '#ff8f00',
        '#1f597e',
        '#00866c',
        '#432c58',
        '#00a38e',
        '#d9af0d',
        '#176c93',
        '#5aaa37',
        '#b3ad1a',
        '#f63077',
        '#ed1c5f',
        '#a6ad1e',
        '#088460',
        '#9f0562',
        '#ffa800',
        '#f53436',
        '#d9316d',
        '#00b9a8',
        '#f02367',
        '#b51062',
        '#009c86',
        '#049bc5',
        '#00b2c0',
        '#611f5b',
        '#e6af09',
        '#4ea93c',
        '#008d75',
        '#6c1b5d',
        '#f74088',
        '#4d2759',
        '#108a5b',
        '#be1b61',
        '#ff7b00',
        '#67aa33',
        '#9aac22',
        '#57235a',
        '#383057',
        '#c62560',
        '#fc4c0e',
        '#319f49',
        '#00c0b0',
        '#f9377f',
        '#80125f',
        '#2e3456',
        '#00957d',
        '#fa441b',
        '#f2b004',
        '#41a940',
        '#0c88b1',
        '#ff5400',
        '#fc3d87',
        '#f02451',
        '#d22e67',
        '#00aa97',
        '#ff9900',
        '#a90163',
        '#e13574',
        '#00bbb5',
        '#ffa100',
        '#ff448f',
        '#39a445',
    ],
    menus: {
        profile: {
            SUMMARY: 'Summary',
            PERSONAL_DETAILS: 'Personal Information',
            ACCOUNT_SETTINGS: 'Account Settings',
            EMPLOYMENT: 'Employment',
            CONTACT: 'Contact Details',
            ROLES_TEAMS: 'Teams & Roles',
            BANK: 'Bank Accounts',
            SUPERANNUATION: 'Superannuation',
            LEAVE: 'Leave',
            TIMESHEETS: 'Timesheets',
            PAY_ADVICES_SUMMARIES: 'Pay Advices & Summaries',
            PAY_ADVICES_SUMMARIES_NZ: 'Pay Advices',
            signInType: {
                USERNAME:  'userName',
                EMAIL_ADDRESS: 'emailAddress',
            },
        },
        newStarters: newStarterMenuOptions,
    },
    request: {
        PENDING: "PENDING",
        ERROR: "ERROR",
        TIMEOUT: "TIMEOUT",
        TIMEOUT_PERIOD: appSettings.TIMEOUT_PERIOD || 30000,
    },
    events: {
        ADD_NEW_STARTER: "ADD_NEW_STARTER",
        CHANGE: 'change',
        CHANGE_COMPANY_LOGO: "CHANGE_COMPANY_LOGO",
        CHANGE_COUNTRY_LOOKUP: 'change-country',
        CHANGE_FILTER: "CHANGE_FILTER",
        CHANGE_LOGIN_PERSON: 'CHANGE_LOGIN_PERSON',
        CHANGE_NEW_STARTER_ACTIONS_COUNT: "CHANGE_NEW_STARTER_ACTIONS_COUNT",
        CHANGE_NEW_STARTER_COMPLETED_PAGES: "CHANGE_NEW_STARTER_COMPLETED_PAGES",
        CHANGE_NEW_STARTER_COUNT: "CHANGE_NEW_STARTER_COUNT",
        CHANGE_NEW_STARTER_STATUS: "CHANGE_NEW_STARTER_STATUS",
        CHANGE_PERMISSION_BY_ROLE: "CHANGE_PERMISSION_BY_ROLE",
        CHANGE_PERSON_DELEGATE_APPROVER: "CHANGE_PERSON_DELEGATE_APPROVER",
        CHANGE_PERSON_EMPLOYER: "CHANGE_PERSON_EMPLOYER",
        CHANGE_PERSON_EMPLOYMENT: "CHANGE_PERSON_EMPLOYMENT",
        CHANGE_PERSON_IDENTITY: 'CHANGE_PERSON_IDENTITY',
        CHANGE_PERSON_LEAVE_ENTITLEMENTS: "CHANGE_PERSON_LEAVE_ENTITLEMENTS",
        CHANGE_PERSON_NOTIFICATIONS: "CHANGE_PERSON_NOTIFICATIONS",
        CHANGE_PERSON_SALARY: "CHANGE_PERSON_SALARY",
        CHANGE_PERSON_TAX: "CHANGE_PERSON_TAX",
        CHANGE_PERSON_TFN: "CHANGE_PERSON_TFN",
        CHANGE_PERSON_WORK_DAYS_HOURS: "CHANGE_PERSON_WORK_DAYS_HOURS",
        CHANGE_SINGLE_PERSON_APPROVER_TYPES: "CHANGE_SINGLE_PERSON_APPROVER_TYPES",
        CHANGE_SINGLE_TEAM: 'CHANGE_SINGLE_TEAM',
        CHANGE_MULTIPLE_TEAMS: 'CHANGE_MULTIPLE_TEAMS',
        CHANGE_MULTIPLE_TEAM_MEMBERS: 'CHANGE_MULTIPLE_TEAM_MEMBERS',
        CHANGE_STATE_LOOKUP: 'change-state',
        CHANGE_SUBURB_LOOKUP: 'change-suburb',
        CHANGE_UNASSIGNED_TEAM: 'CHANGE_UNASSIGNED_TEAM',
        CHANGE_USER_IDP: 'CHANGE_USER_IDP',
        CHECK_MEMBERSHIP: 'CHECK_MEMBERSHIP',
        CLOSE_CURRENT_PERSON_IDENTITY: 'CLOSE_CURRENT_PERSON_IDENTITY',
        DELETE_MEMBERSHIP: "DELETE_MEMBERSHIP",
        DELETE_NEW_STARTER: "DELETE_NEW_STARTER",
        DELETE_TEAM: 'DELETE_TEAM',
        DOWNLOAD_LEAVE_ATTACHMENT: "DOWNLOAD_LEAVE_ATTACHMENT",
        DOWNLOAD_PERSON_PAY_ADVICE_PDF: 'DOWNLOAD_PERSON_PAY_ADVICE_PDF',
        DOWNLOAD_PERSON_PAY_SUMMARY_PDF: 'DOWNLOAD_PERSON_PAY_SUMMARY_PDF',
        EDIT: 'edit',
        GET_PEOPLE_ACTIVATION_STATUS: 'GET_PEOPLE_ACTIVATION_STATUS',
        GET_ACTIVATION_TOKEN: "GET_ACTIVATION_TOKEN",
        GET_CONFIGURATION_VALUES: "GET_CONFIGURATION_VALUES",
        GET_DEFAULT_SUPER_FUNDS: "GET_DEFAULT_SUPER_FUNDS",
        GET_ROLES_AND_TEAMID: "GET_ROLES_AND_TEAMID",
        GET_MCS_VERSIONS: "GET_MCS_VERSIONS",
        GET_WEB_API_LOGS: "GET_WEB_API_LOGS",
        LOGIN: 'LOGIN',
        RESEND_FORMS_NEW_STARTER: 'RESEND_FORMS_NEW_STARTER',  
        RESET_IDENTITY_PASSWORD: 'RESET_IDENTITY_PASSWORD',
        UPDATE_ADMIN_ROLES: "UPDATE_ADMIN_ROLES",
        UPDATE_BANKS: 'UPDATE_BANKS',
        UPDATE_CONFIG_VALUE: 'UPDATE_CONFIG_VALUE',
        UPDATE_EMERGENCY_CONTACTS: 'UPDATE_EMERGENCY_CONTACTS',
        UPDATE_LEAVE_APPS: 'UPDATE_LEAVE_APPS',
        UPDATE_LEAVE_APPS_TRACES: 'UPDATE_LEAVE_APPS_TRACES',
        UPDATE_PERSON_AVATAR: "UPDATE_PERSON_AVATAR",
        UPDATE_PERSONAL_CONTACTS: 'UPDATE_PERSONAL_CONTACTS',
        UPDATE_TEAM: 'UPDATE_TEAM',
        UPDATE_WORK_CONTACTS: 'UPDATE_WORK_CONTACTS',
        UPDATE_WORKFLOW_SCOPE: 'UPDATE_WORKFLOW_SCOPE',
        CHANGE_PERSON_TIMESHEETS: 'CHANGE_PERSON_TIMESHEETS',
        UPDATE_PERSON_TIMESHEETS: 'UPDATE_PERSON_TIMESHEETS',
        ACTIVATE_ALL_USERS: 'ACTIVATE_ALL_USERS',
        ACTIVATE_USERS: 'ACTIVATE_USERS',
    },
    api_urls: {
        ACTIVATION: apiBaseUrl + '/UserCreationTrace',
        ACTIVATION_TOKEN: `${eApiBaseUrl}/UserIdentity/ActivationToken`,
        APPROVALS: apiBaseUrl + '/Approval',
        CONFIGURATION: apiBaseUrl + '/Configuration',
        COSTACCOUNTS_LOOKUP: apiBaseUrl + '/CostAccountLookup',
        COUNTRIES_LOOKUP: apiBaseUrl + '/CountryLookup',
        CURRENT_USER: apiBaseUrl + '/CurrentUser',
        DEFAULT_MCS_VERSION: apiBaseUrl + '/McsVersion/Default',
        DEFAULT_SUPER_FUNDS: apiBaseUrl + '/DefaultSuperFunds',
        DELEGATED_APPROVER: apiBaseUrl + '/RedirectedApprover',
        DELEGATED_TO_ME: apiBaseUrl + '/RedirectedOriginalApprovers',
        GET_ACTIVATION_STATUS: apiBaseUrl + '/GetActivationStatus',
        GET_PEOPLE: apiBaseUrl + '/GetPeople',
        GET_PEOPLE_SUMMARY: apiBaseUrl + '/GetPeopleSummary',
        GET_PEOPLE_BY_TEAM_IDS: apiBaseUrl + '/GetPeopleByTeamIds',
        GET_PERSON_WORK_HOURS_DAYS: apiBaseUrl + '/EmployeeWorkDaysAndHours',
        GET_SINGLE_WORKFLOW_SCOPE: apiBaseUrl + '/{0}/WorkflowDetails',
        GET_UNASSIGNED_TEAM_INFO: apiBaseUrl + '/CurrentUser/DisplayType/UnassignedTeam/Teams',
        GET_USER_WORKFLOW_SCOPES: apiBaseUrl + '/CurrentUser/WorkflowScopes',
        GET_USERS_MANAGERS: apiBaseUrl + '/CurrentUser/MyManagerInfo',
        GET_WORKFLOW_SCOPES: apiBaseUrl + '/GetWorkflowScopes',
        LEAVE_APPLICATIONS: apiBaseUrl + '/LeaveApplications',
        LEAVE_REASON: apiBaseUrl + '/LeaveReasons',
        LEAVE_TYPE: apiBaseUrl + '/LeaveType',
        LOGIN_PERSON: apiBaseUrl + '/CurrentUser/Login',
        LOGO: apiBaseUrl + '/Logo',
        LOOKUP: apiBaseUrl + '/Lookup',
        MASS_ACTIVATION: apiBaseUrl + '/PersonIdentity/MassActivation',
        MASS_ACTIVATION_ALL: apiBaseUrl + '/PersonIdentity/MassActivationAll',
        MCS_VERSIONS: apiBaseUrl + '/McsVersion', 
        NEW_STARTERS: apiBaseUrl + '/NewStarter',
        PERMISSIONS: apiBaseUrl + '/Permission',
        PERSON: apiBaseUrl + '/Person',
        PERSON_ADDRESS_CONTACT: apiBaseUrl + '/Person/{0}/PersonAddressContact',
        PERSON_IDENTITY: apiBaseUrl + '/PersonIdentity',
        PERSON_SUPERANNUATION: apiBaseUrl + '/PersonSuperannuation',
        REPORTS: apiBaseUrl + '/Report',
        ROLE: apiBaseUrl + '/Role',
        STATES_LOOKUP: apiBaseUrl + '/StateLookup',
        SUBURB_LOOKUP: apiBaseUrl + '/SuburbLookup',
        TEAM: apiBaseUrl + '/Team',
        TEAM_MANAGEMENT: apiBaseUrl + '/TeamManagement',
        TEAM_MEMBERS_COUNT: apiBaseUrl + '/TeamMembersCount',
        TEAMS: apiBaseUrl + '/Teams',
        TENANT: apiBaseUrl + '/Tenant',
        TIMESHEET: apiBaseUrl + '/Timesheet',
        USER: apiBaseUrl + '/User',
        WEBAPI: apiBaseUrl + '/WebApi',
        WEBAPI_LOG: apiBaseUrl + '/WebApiLog',
        WEBAPICONFIGURATION: apiBaseUrl + '/WebApiConfiguration',
        WORKFLOW: apiBaseUrl + '/Workflow',
        WORKFLOW_DETAIL: apiBaseUrl + '/SinglePersonWorkflowScope',
        WORKSPACE_MIGRATION: apiBaseUrl + '/WorkspaceMigration',
    },
    error: {
        TEAMS: {
            GET_TEAMS: 'There was a problem retrieving the list of teams',
            GET_TEAM: 'There was a problem retreiving the team details',
            NAME_EXISTS: 'Team name already exists',
        },
        GET_EMPLOYEE: 'There was a problem retrieving the list of users',
        GET_CLIENTS: '',
        GET_ROLES: 'There was a problem retrieving the list of roles',
        GET_PERSONS: 'There was a problem retrieving the list of people',
        GET_PERSONS_LEAVE_APPLICATIONS: 'There was a problem retrieving leave applications',
        GET_PERSONS_LEAVE_BALANCES: 'There was a problem retrieving leave applications',
        GET_SINGLE_PERSON: 'There was a problem retrieving the user. Please try again shortly.',
        ACTIVATE_USER: 'There was a problem processing your request. Please try again shortly.',
        ACTIVATION_LINK_EXPIRED: 'Activation Link no longer Valid',
        GET_API_KEY: "There was a problem retrieving the Api Key",
        FORMS: {
            FORM_FIELD_REQUIRED: 'Required',
            IS_NUMERIC: 'Invalid numeric value. ',
            IS_ALPHA_NUMERIC: 'Letters and numbers only. ',
            IS_LENGTH: 'Must be {0} digits. ',
            MAX_LENGTH: 'Maximum length is {0}. ',
            MIN_LENGTH: 'Minimum length is {0}. ',
            INVALID_COUNTRY: 'Invalid country. ',
            INVALID_EMAIL: 'This is not a valid email. ',
            DATE_FORMAT: minimalDateFormat.toLowerCase() + ' format. ',
            BSB_FORMAT: 'Must be 6 numbers. ',
            CURRENCY_FORMAT: 'Invalid currency value. ',
            PHONE_FORMAT: 'Number + and - only. ',
            BANK_NZ_ACCOUNT_NUMBER: 'Must be 9 or 10 numbers. ',
            BANK_ACCOUNT_NAME: 'No apostrophes, double quotes or line breaks. ',
            NUMERIC_WITH_SPACES: 'Numbers and spaces only. ',
            PERCENTAGE_FORMAT: 'Invalid percentage value. ',
            GREATER_THAN_ZERO: 'Must be greater than 0. ',
            BANK_NAME: 'No apostrophes or double quotes. ',
            SMART_TIME_VALIDATION: "Invalid time.",
        },
        WORKFLOW: {
            NAME_EXISTS: 'Workflow name already exists',
            APPROVAL_PROCESS: 'There was a problem retrieving the {0}, Please try again shortly',
        },
        PAGE_SECTION: {
            ERROR: 'Could not retrieve {0}',
        },
        STATUS_CODE: {
            403: ':( Sorry, you are not allowed to view this information.',
        },
        GENERIC_ERROR: ':\\\ Oops, an unexpected error occurred.',
        GENERIC_ERROR_DESC: 'We suggest refreshing the page and trying again.',
        OLD_PASSWORD: 'The new password must be different from the old password.',
        ROLES: {
            NAME_EXISTS: 'Role name already exists',
        },
    },
    regex: {
        DATE: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/,
        BSB: /^\d{6}$/,
        CURRENCY: /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/,
        PHONE: /^[+\-\d]*$/,
        BANK_NZ_ACCOUNT_NUMBER: /^\d{9,10}$/,
        BANK_ACCOUNT_NAME: /^[^\"\'\n]*$/,
        NUMERIC_WITH_SPACES: /^[\s\d]*$/,
        PERCENTAGE: /^(100(\.00?)?|[1-9]?\d(\.\d\d?)?)$/,
        BANK_NAME: /^[^\"\']*$/,
    },
    message: {
        SELECT_DEFAULT: '-- Please select --',
        NO_DISPLAY_RECORDS: 'no records found',
        SYSTEM_EMAILS: "Notifications",
    },
    sortOrders: {
        ASCENDING: 'Ascending',
        DESCENDING: 'Descending',
    },
    dates: {
        formats: {
            MINIMAL: minimalDateFormat,                 // 31/01/2016
            FULL: 'D MMM YYYY',                         // 1 Jan 2016
            FULL_WITH_WEEKDAY: 'ddd D MMM YYYY',        // Fri 1 Jan 2016
            FULL_WITH_WEEKDAY_AND_TIME: 'ddd D MMM YYYY h:mm A', // Fri 1 Jan 2016 2:00 pm
            SERVER_POST_FORMAT: 'YYYY-MM-DD',           // 2016-01-31
            FULL_MONTH_YEAR_ONLY: 'MMMM YYYY',          // January 2016
            ABBR_MONTH_YEAR_ONLY: 'MMM YYYY',           // Jan 2016
            WEEKDAY: 'ddd',                             // Fri
            TIME_PORTION: 'h:mm A',                      // 1:58 PM
        },
    },
    times: {
        AM: "AM",
        PM: "PM",
    },
    permission_statuses: {
        DENY: 'Deny',
        VIEW: 'View',
        EDIT: 'Edit',
        MIXED: 'Mixed',
        EDIT_DENY: 'EditDeny',
        VIEW_DENY: 'ViewDeny',
        EDIT_VIEW: 'EditView',
    },
    response_codes: {
        validate_activation_id: {
            1: "Valid",
            2: "LinkExpired",
            3: "Used",
            4: "NotFound",
            5: "Invalid",
            6: "InvalidPasscode",
        },
        check_membership: {
            validNoOtherTeams: 1,
            validOtherTeams: 2,
            invalid: 3,
        },
        person_scope: {
            default: "Default",
        },
        add_membership: {
            Valid_NotExistInTeam: "Valid_NotExistInTeam",
            Valid_ExistInTeam: "Valid_ExistInTeam",
            Valid_NoTargetTeam: "Valid_NoTargetTeam",
            Invalid_ExistInTargetTeam: "Invalid_ExistInTargetTeam",
            Invalid_CannotAssignUserIntoUnassignedTeam: "Invalid_CannotAssignUserIntoUnassignedTeam",
            Invalid_PendingWorkflowToApproveExist: "Invalid_PendingWorkflowToApproveExist",
            Valid_HasPendingWorkflowForApproval: "Valid_HasPendingWorkflowForApproval",
        },
        remove_membership: {
            Ok: "Ok",
            Warning: "Warning",
            Error: "Error",
        },
    },
    reports: {
        LEAVE_BALANCES: "leave-balances",
        MEDICAL_AND_EMERGENCY_REPORT: "medical-and-emergency-report",
        LEAVE_HISTORY: "leave-history",
        PERSONAL_CHANGES_AUDIT_REPORT: "profile-changes-audit-report",
        LEAVE_AUDIT_REPORT: "leave-applications-audit-report",
        MAINTENANCE_AUDIT_REPORT: "maintenance-audit-report",
        ACTIVATION_REPORT: "activation-report",
        TEAMS_REPORT: "teams-report",
        ALL_ROLES_REPORT: "all-roles-report",
        ROLE_TYPE_REPORT: "role-type-report",
        SINGLE_ROLE_REPORT: "role-report",
        APPLIED_LEAVE_BY_DATE: "Applied-leave-by-date",
    },
    countries: {
        AUS: "Australia",
        NZ: "New Zealand",
    },
    ENUMS: {
        ACCOUNT_ACTIVATION_STATUSES: {
            LOGIN_DISABLED: 'Login disabled',
            ACTIVATED: 'Activated',
            ACCOUNT_ACTIVATED: 'Account activated',
            ACTIVATION_LINK_SENT: 'Activation link sent',
            ACTIVATION_LINK_EXPIRED: 'Activation link expired',
            NOT_ACTIVATED: 'Not activated',
        },
        UNLOCK_ACCOUNT: {
            NONE: 0,
            UNLOCK_ESS: 1,
            UNLOCK_AUTH: 2,
            UNLOCK_BOTH: 3,
        },
        APPROVALS_VIEW_OPTIONS: {
            ALL: {
                LABEL: 'All',
                VALUE: 'all',
            },
            LEAVE:
            {
                LABEL: 'Leave Applications',
                VALUE: workflowType.LEAVE,
            },
            PERSONAL_CHANGES: {
                LABEL: 'Profile Changes',
                VALUE: workflowType.PERSONAL_CHANGES,
            },
        },
        APPROVER_TYPES: {
            ADMIN: 'Admin',
            MANAGER: 'Manager',
            REDIRECTED_APPROVER: 'RedirectedApprover',
            OUT_OF_TEAM_APPROVER_FOR_LEAVE: 'OutOfWorkgroupApproverForLeave',
            OUT_OF_TEAM_APPROVER_FOR_PERSONAL_CHANGES: 'OutOfWorkgroupApproverForPersonalChanges',
        },
        IDENTITY_PROVIDER:{
            B2C: 1,
            ACCESS: 2,
        },
        INDIGENOUS_STATUS: {
            Unknown: "Unknown",
            AboriginalTorresStrait: "Aboriginal & Torres Strait",
            NotAboriginalOrTorresStraitIslander: "Not Aboriginal or Torres Strait Islander",
            Aboriginal: "Aboriginal",
            TorresStraitIslander: "Torres Strait Islander",
            Declined: "Declined",
        },
        LEAVE_DIRECTION: {
            before: 'before',
            after: 'after',
        },
        LEAVE_STATUS: {
            NOT_EXIST: 'NotExist',
            OPENED: 'Opened',
            PUBLISHED: 'Published',
            PENDING: 'Submitted',
            DECLINED: 'Declined',
            IN_APPROVAL: 'Inapproval',
            APPROVED: 'Approved',
            CANCELLED: 'Cancelled',
            PAYROLL_SENT: 'PayrollSent',
            PAYROLL_APPROVED: 'PayrollApproved',
            PAYROLL_DECLINED: 'PayrollDeclined',
        },
        LEAVE_STATUS_LABELS: {
            PENDING_APPROVAL: 'Pending Approval',
            PENDING_CANCELLATION_APPROVAL: 'Pending Cancellation Approval',
            IN_APPROVAL: 'In Approval',
            APPROVED: 'Approved',
            CANCELLED: 'Cancelled',
            CANCELLATION_INAPPROVAL: 'Cancellation in Approval',
            DECLINED: 'Declined',
            PAYROLL_DECLINED: 'Rejected',
        },
        LEAVE_SORTING_TYPE: {
            LEAVE_DATE: 'LeaveDate',
            MODIFIED_DATE: 'ModifiedDate',
        },
        LEAVE_HALFDAY_SESSIONS: {
            NONE: { 
                VALUE: '0',
                LABEL: '',
            },
            FIRST_HALF: {
                VALUE: '1',
                LABEL: '1st Half',
            },
            SECOND_HALF: {
                VALUE: '2',
                LABEL: '2nd Half',
            },
        },
        NEW_STARTER_SCREENS_MAP: {
            [newStarterOptionsSlugs.PERSONAL_DETAILS]: "PersonalInformation",
            [newStarterOptionsSlugs.CONTACT]: "ContactDetails",
            [newStarterOptionsSlugs.EMPLOYMENT]: "Employment",
            [newStarterOptionsSlugs.TAX_DETAILS]: "TaxDetails",
            [newStarterOptionsSlugs.SUPERANNUATION]: "SuperannuationFund",
            [newStarterOptionsSlugs.BANK]: "BankAccount",
        },
        NEW_STARTER_WORKFLOW_STATUSES: {
            NEW_STARTER_STARTED: "NewStarterStarted",
            NEW_STARTER_IN_PROGRESS: "NewStarterInProgress",
            NEW_STARTER_SUBMITTED: "NewStarterSubmitted",
            NEW_STARTER_APPROVED: "Approved",
            NEW_STARTER_PAYROLL_SENT: "PayrollSent",
            NEW_STARTER_PAYROLL_SYNCED: "NewStarterPayrollSynced",
            NEW_STARTER_RESEND_TO_PAYROLL: "NewStarterResendToPayroll",
        },
        NEW_STARTER_CHANGE_STATUS_ACTIONS: {
            START: 'Start',
            COMPLETE: 'Complete',
            SEND_TO_PAYROLL: 'SendToPayroll',
            EDIT_DETAILS: 'EditDetails',
        },
        PAYMENT_ADVICE_SUMMARIE: {
            Advices: "Advices",
            Summaries: "Summaries",
        },
        PERMISSIONS: {
            MASS_ACTIVATION: 'MassActivation',
            AUDIT_REPORTS : 'AuditReports',
        },
        PERMISSIONS_LABEL_MAP: {
            Addresses: 'Address',
            BankDetails: 'Bank Accounts',
            SuperannuationDetails: 'Superannuation Funds',
            ApprovePersonalChanges: 'Approve Profile Changes',
            AddRemoveMemberFromTeam: 'Organise Teams',
            AddRemovePersonFromRole: 'Adding / Removing a User from a Role',
            MemberDirectoryForAllMembers: 'View Directory',
            RedirectApproval: "Redirect Approvals",
            Syncing: "General",
        },
        ROLE_TYPES: {
            ADMINISTRATOR: 'Administrator',
            MANAGER: 'Manager',
            EMPLOYEE: 'Personal',
        },
        ROLE_TYPES_MAP: {
            Admin: 'Administrator',
            Manager: 'Manager',
            Personal: 'Personal',
            Default: 'Default',
        },
        TEAM_ORGANISING_ACTION: {
            ADD_A_PERSON_TO_THIS_TEAM: 'ADD_A_PERSON_TO_THIS_TEAM',
            ADD_THIS_PERSON_TO_A_TEAM: 'ADD_THIS_PERSON_TO_A_TEAM',
            MOVE_THIS_PERSON_TO_A_TEAM: 'MOVE_THIS_PERSON_TO_A_TEAM',
            REMOVE_THIS_PERSON_FROM_THIS_TEAM: 'REMOVE_THIS_PERSON_FROM_THIS_TEAM',
            CHANGE_THIS_PERSONS_ROLE: 'CHANGE_THIS_PERSONS_ROLE',
            MOVE_FROM_UNASSIGNED: 'MOVE_FROM_UNASSIGNED',
        },
        TEAM_VIEW_OPTIONS: {
            BASIC: 'Basic',
            SUMMARY: 'Summary',
            LEAVE: 'Leave',
            LEAVE_DASHBOARD: 'LeaveDashboard',
        },
        TEAM_REQUEST_TYPES: {
            SINGLE_TEAM: {
                NAME: 'SingleTeam',
                ID: 0,
            },
            EVERYONE: {
                NAME: 'Everyone',
                ID: 1,
                LABEL: 'Directory',
            },
            EVERYONE_IN_MY_TEAMS: {
                NAME: 'EveryoneInMyTeams',
                ID: 2,
                LABEL: 'All Team Members',
            },
            EVERYONE_IN_TEAMS_IM_A_MEMBER: {
                NAME: 'EveryoneInTeamsImAMemberOf',
                ID: 3,
                LABEL: 'Teams I am a member of',
            },
            EVERYONE_IN_MY_REPORTING_TEAMS: {
                NAME: 'EveryoneInMyReportingTeams',
                ID: 4,
                LABEL: 'Teams I manage',
            },
            UNASSIGNED_TEAM: {
                NAME: 'UnassignedTeam',
                ID: 5,
                LABEL: 'Unassigned',
            },
            EVERYONE_IN_ASSOCIATED_TEAMS: {
                NAME: 'EveryoneInAssociatedTeams',
                ID: 6,
                LABEL: 'Associated teams',
            },
            OTHER_TEAMS: {
                NAME: 'OtherTeams',
                ID: 7,
                LABEL: 'Other teams',
            },
            MULTIPLE_TEAMS: {
                NAME: 'MultipleTeams',
                ID: 8,
            },
        },
        WORKFLOW_ACTIONS: {
            Leave: {
                title: 'submits',
                descriptor: 'any leave application',
                descriptor2: 'leave applications',
                icon: 'icon-leave',
                iconColorClass: 'icon-color4',
                displayName: 'Leave',
                singularDisplayName: 'Leave',
                allLeaveReasons: 'All Leave Reasons',
                specificLeaveReasons: 'Specific Leave Reasons',
                allLeaveReasonPrefix: 'for',
                leaveReasonPrefix: 'and this leave reason is',
                chooseDbPrefix: 'with database',
                options: [
                    {
                        id: workflowResourceType.SICK_LEAVE,
                        label: 'a Personal Leave application',
                        icon: 'icon-personal-leave',
                        location: 'members/{0}/leave/',
                    }, {
                        id: workflowResourceType.ANNUAL_LEAVE,
                        label: 'an Annual Leave application',
                        icon: 'icon-annual-leave',
                        location: 'members/{0}/leave/',
                    }, {
                        id: workflowResourceType.LONG_SERVICE_LEAVE,
                        label: 'a Long Service Leave application',
                        icon: 'icon-long-service-leave',
                        location: 'members/{0}/leave/',
                    }, {
                        id: workflowResourceType.OTHER_LEAVE,
                        label: 'an Other Leave application',
                        icon: 'icon-leave',
                        location: 'members/{0}/leave/',
                    }, {
                        id: workflowResourceType.USER_DEFINED_LEAVE,
                        label: 'a User Defined Leave application',
                        icon: 'icon-leave',
                        location: 'members/{0}/leave/',
                    },
                ],
            },
            PersonalChanges: {
                title: 'changes',
                descriptor: 'any details of their profile',
                descriptor2: 'profile changes',
                icon: 'icon-user',
                iconColorClass: 'icon-color1',
                displayName: 'Profile changes',
                singularDisplayName: 'Profile change',
                options: [
                    {
                        id: workflowResourceType.NAME,
                        label: 'Name',
                        icon: 'icon-user',
                        iconColorClass: 'icon-color1',
                        location: 'members/{0}/personal-information/',
                    }, {
                        id: workflowResourceType.DEMOGRAPHIC,
                        label: 'Additional information',
                        icon: 'icon-user',
                        iconColorClass: 'icon-color1',
                        location: 'members/{0}/personal-information/',
                    }, {
                        id: workflowResourceType.MEDICAL_CONDITIONS,
                        label: 'Medical conditions',
                        icon: 'icon-user',
                        iconColorClass: 'icon-color1',
                        location: 'members/{0}/personal-information/',
                    }, {
                        id: workflowResourceType.PERSONAL_CONTACTS,
                        label: 'Personal contact',
                        icon: 'icon-contact',
                        iconColorClass: 'icon-color1',
                        location: 'members/{0}/contact-details/',
                    }, {
                        id: workflowResourceType.WORK_CONTACTS,
                        label: 'Work contact',
                        icon: 'icon-contact',
                        iconColorClass: 'icon-color1',
                        location: 'members/{0}/contact-details/',
                    }, {
                        id: workflowResourceType.ADDRESS,
                        label: 'Address',
                        icon: 'icon-contact',
                        iconColorClass: 'icon-color1',
                        location: 'members/{0}/contact-details/',
                    }, {
                        id: workflowResourceType.EMERGENCY_CONTACTS,
                        label: 'Emergency contact',
                        icon: 'icon-contact',
                        iconColorClass: 'icon-color1',
                        location: 'members/{0}/contact-details/',
                    }, {
                        id: workflowResourceType.BANK_ACCOUNTS,
                        label: 'Bank accounts',
                        icon: 'icon-bank',
                        iconColorClass: 'icon-color3',
                        location: 'members/{0}/bank-accounts/',
                    },
                ],
            },
            Timesheet: {
                title: 'submits',
                descriptor: 'any timesheet',
                descriptor2: 'timesheets',
                icon: 'icon-timesheets',
                iconColorClass: 'icon-color-timesheets',
                displayName: 'Timesheet',
                singularDisplayName: 'Timesheet',
                options: null,
            },
        },

        WORKFLOW_TYPES: {
            PERSONAL_CHANGES: workflowType.PERSONAL_CHANGES,
            LEAVE: workflowType.LEAVE,
            TIMESHEETS: workflowType.TIMESHEETS,
        },
        WORKFLOW_RESOURCE_TYPE: workflowResourceType,
        WORKFLOW_APPROVAL_TYPE: {
            ANY_APPROVER: 'AnyManager',
            AUTO_APPROVE: 'AutoApprove',
            ALL_APPROVER: 'AllManagers',
            DO_NOTHING: 'DoNothing',
            OUT_OF_TEAM_APPROVER: 'AnyOutOfWorkgroupApprover',
        },
        WORKFLOW_APPROVAL_TEAM_TYPE: {
            ANOTHER_TEAM: 'TargetTeam',
            CURRENT_TEAM: 'CurrentTeam',
            OUT_OF_TEAM_APPROVER: 'OutOfWorkgroupApprovers',
        },
        BASIS_OF_EMPLOYMENT: {
            FULLTIME_EMPLOYMENT: 'Full-time employment',
            PARTTIME_EMPLOYMENT: 'Part-time employment',
            LABOUR_HIRE: 'Labour hire',
            SUPER_ANNUATION_OR_ANNUITY_INCOME_STREAM: 'Superannuation or annuity income stream',
            CASUAL_EMPLOYMENT: 'Casual employment',
        },
        TAX_PURPOSES: {
            AUSTRALIAN_RESIDENT: 'An Australian resident for tax purposes',
            FOREIGN_RESIDENT: 'A foreign resident for tax purposes',
            HOLIDAY_MAKER: 'A working holiday maker',
        },
        EMAIL_CONTACT_TYPE:{
            PERSONAL_EMAIL:'PersonalEmail',
            WORK_EMAIL:'WorkEmail',
        },
        USER_CREATION_STATUS :{
            INACTIVE: 0,
            PENDING : 1,
            EXPIRED : 2,
            ACTIVE : 3,
            DISABLED : 4, 
        },
    },
    newStarterOptionsSlugs,
    newStarterWizardOrder: [
        newStarterOptionsSlugs.WELCOME,
        newStarterOptionsSlugs.PERSONAL_DETAILS,
        newStarterOptionsSlugs.CONTACT,
        newStarterOptionsSlugs.EMPLOYMENT,
        newStarterOptionsSlugs.TAX_DETAILS,
        newStarterOptionsSlugs.SUPERANNUATION,
        newStarterOptionsSlugs.BANK,
        newStarterOptionsSlugs.SUMMARY,
    ],
    spinner_options: {
        main: {
            color: '#38c72a', // #rbg or #rrggbb
            id: 'loader-spinner',
        },
        modal: {
            id: 'modal-loader-spinner',
        },
        small: {
            color: '#51534a', // #rbg or #rrggbb
        },
        danger: {
            color: '#ff1e00',
        },
        pending: {
            color: '#ff7200',
        },
    },

    default_doughnut_colour: '#666',
    default_paging_size: 20,
    default_teamMember_paging_size: 10,
    leaveTypePermissions: {
        BALANCE: 'Display balance',
        INSUFFICIENT_BALANCE: 'Allow insufficient balance',
        PRO_RATA: 'Include pro rata calculation',
        HOUR_APPLICATION: 'Allow hour application',
        LSL_PRO_RATA_CALC: 'Only once entitlement is reached',
    },
    unicodeChars: {
        RIGHT_POINTING_ANGLE: '\u203A',
    },
    NOT_PROVIDED: "Not provided",
    STORAGE: {
        USER_IDP: 'auth.user.idp',
        USER: "auth.user",
    },
};

export default appConstants;
